import "regenerator-runtime/runtime";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";

import "../../../client/stylesheets/mdb.css.scss";
import "../../../client/stylesheets/landing.css.scss";

import React from "react";
import { Provider } from "react-redux";
import ReactPixel from "react-facebook-pixel";
const advancedMatchingPixel = {};
const optionPixels = {
  autoConfig: true,
  debug: false,
};

import { createStore } from "redux";
import App from "./App";

const store = createStore(() => {
  return {};
});

class Layout extends React.Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (window.RAILS_ENV === "production") {
      ReactPixel.init("221553698566333", advancedMatchingPixel, optionPixels);
    } else {
      ReactPixel.init("231084487711058", advancedMatchingPixel, optionPixels);
    }
  }

  render() {
    return (
      <Provider store={store}>
        <App />
      </Provider>
    );
  }
}

export default Layout;
